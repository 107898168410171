import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { CustomEase } from "gsap/all";
import { Filter } from "pixi.js";
import { GameConfiguration } from "../../game-configuration.interface";
import {
    GameDefinition,
    GameReel,
    GameReelsDefinition
} from "../../game.properties";
import { GameReelLayerProps } from "./game-reel-layer.props";
import { GameSymbolLayer } from "../symbol/game-symbol.layer";
import { ICustomEase } from "../../../../types/gsap";
import { MotionBlurFilter } from "pixi-filters";
import { PixiJSLayerPool } from "../../../../common/utilities/pixijs-layer-pool";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import gsap from "gsap";

export class GameReelLayer<TSymbols extends string = string, TReels extends number = 1> extends PixiJSRenderingLayer<
GameConfiguration,
{
    symbols: PixiJSLayerPool<GameConfiguration, GameSymbolLayer>;
},
"",
GameReelLayerProps<TSymbols>,
PIXI.Container
> {
    public readonly reelSymbols: TSymbols[];
    private reelEaseFunction: gsap.EaseFunction;
    private currentResultIndex = 0;
    // private blurFilter = new PIXI.filters.BlurFilter(0, 2, Math.round(PIXI.settings.RESOLUTION));
    private blurFilter = new MotionBlurFilter([ 0, 0 ]);
    private visibleSymbols = new Map<number, [TSymbols, GameSymbolLayer]>();
    private animation: gsap.core.Tween | undefined;
    private readonly reelOverrides = new Map<number, TSymbols>();
    private symbolSounds = new Map<TSymbols, Audio[]>();
    private reelLandingSound: Audio[] = [];
    private isSkipping = false;
    private numberOfReels = 0;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly index: number,
        public readonly game: GameDefinition,
        public readonly reels: GameReelsDefinition<TSymbols, TReels>
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "idle",
                anticipating: [],
                paylines: [],
                picks: null,
                result: null,
                scatters: null,
                score: null,
                wilds: null,
            },
            {
                symbols: new PixiJSLayerPool(
                    () => new GameSymbolLayer(configuration, app, game)
                ),
            }
        );

        this.numberOfReels = Object.keys(reels.symbols).length;
        this.reelEaseFunction = (CustomEase as ICustomEase).create("custom", reels.ease);
        this.reelSymbols = reels.symbols[`reel${index}` as GameReel<TReels>];

        this.reelLandingSound = AudioHelper.getAudioList(game.sounds.land);
        for (const symbol in game.symbols) {
            if (!Object.prototype.hasOwnProperty.call(game.symbols, symbol)) {
                continue;
            }

            this.symbolSounds.set(
                symbol as TSymbols,
                AudioHelper.getAudioList(game.symbols[symbol as TSymbols].sound)
            );
        }
    }

    public getMaxSpinDuration(): number {
        if (this.isSkipping || this.configuration.isFastModeActive) {
            return this.reels.duration;
        }

        return ((this.numberOfReels - 1) * this.reels.spinGranularDelay) + this.reels.duration;
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);

        const size = Math.min(this.reels.size, width);
        this.shapes.symbols.resize(size, height);
        this.shapes.symbols.move((width - size) / 2, 0);
        this.updateSymbols();
    }

    public setProps(props: Partial<GameReelLayerProps<TSymbols>>): void {
        super.setProps(props);
        this.updateSymbols();
    }

    public start(): Promise<void> {
        this.setProps(
            {
                anticipating: [],
                paylines: [],
                result: null,
                state: "starting",
            }
        );

        const direction = this.reels.direction || ((this.index % 2) - 1);
        const overlap = this.reels.windowsSize + 1;
        const speed = Math.max(this.reels.speed, 1) * Math.sign(direction);
        const offset = (this.reels.windowsSize * speed) + (overlap * Math.sign(direction));

        const continuationSpeedPerSecond = (
            (this.reelEaseFunction(0.51) - this.reelEaseFunction(0.49)) / // 2 percent of change
            (this.reels.duration / 50) // over 2 percent of time
        ) * offset;

        const spinAnimation = () => {
            const currentIndex = this.currentResultIndex % this.reelSymbols.length;
            this.animation = gsap.fromTo(
                this,
                {
                    currentResultIndex: currentIndex,
                },
                {
                    currentResultIndex: currentIndex + continuationSpeedPerSecond,
                    duration: 1,
                    ease: "none",
                    onComplete: () => {
                        spinAnimation();
                    },
                    onUpdate: () => {
                        this.updateSymbols();
                    },
                }
            );
        };

        const duration = this.reels.duration / 2;
        const delay = (this.isSkipping || this.configuration.isFastModeActive) ? 0 : (this.index * this.reels.spinGranularDelay);
        const newIndex = this.currentResultIndex + offset;
        this.animation?.kill();
        this.animation = gsap.to(
            this,
            {
                currentResultIndex: newIndex,
                duration,
                delay,
                ease: this.reelEaseInFunction,
                onStart: () => {
                    // this.spinningSound.seek(0);
                    // this.spinningSound.play();
                },
                onUpdate: () => {
                    this.updateSymbols();
                },
                onComplete: () => {
                    spinAnimation();
                    this.setProps(
                        {
                            state: "spinning",
                        }
                    );
                },
            }
        );

        return new Promise(
            (resolve) => {
                this.container.filters = [ this.blurFilter as unknown as Filter ];
                gsap.to(
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    this.blurFilter.velocity,
                    {
                        y: this.width / 5,
                        duration,
                        delay,
                        ease: "power2.in",
                        onComplete: resolve,
                    }
                );
            }
        );
    }

    public stop(result: number, scatters: TSymbols[], wilds: TSymbols[], anticipating: Array<[TSymbols, number]>, anticipationMultiplier: number): Promise<void> {
        this.setProps(
            {
                result,
                scatters,
                wilds,
                anticipating,
                state: "stopping",
            }
        );

        const delay = (this.isSkipping || this.configuration.isFastModeActive) ? 0 : ((this.index * this.reels.resultGranularDelay) + (anticipationMultiplier * (this.reels.anticipationDelay ?? 0)));
        return new Promise(
            (resolve) => {
                setTimeout(
                    () => {
                        const direction = this.reels.direction || ((this.index % 2) - 1);
                        const overlap = this.reels.windowsSize + 1;
                        const speed = Math.max(this.reels.speed, 1) * Math.sign(direction);
                        const offset = (this.reels.windowsSize * speed) + (overlap * Math.sign(direction));

                        this.animation?.kill();
                        this.animation = undefined;
                        this.currentResultIndex = this.currentResultIndex % this.reelSymbols.length;
                        const finalOffset = Math.ceil(this.currentResultIndex + offset);

                        for (let i = -overlap; i < (this.reels.windowsSize + overlap); i++) {
                            let overrideIndex = finalOffset + i;
                            overrideIndex = overrideIndex % this.reelSymbols.length;
                            while (overrideIndex < 0) {
                                overrideIndex += this.reelSymbols.length;
                            }

                            let resultIndex = (result || 0) + i;
                            resultIndex = resultIndex % this.reelSymbols.length;
                            while (resultIndex < 0) {
                                resultIndex += this.reelSymbols.length;
                            }

                            this.reelOverrides.set(overrideIndex, this.reelSymbols[resultIndex]);
                        }

                        const duration = this.reels.duration / 2;
                        gsap.to(
                            this,
                            {
                                currentResultIndex: finalOffset,
                                duration,
                                ease: this.reelEaseOutFunction,
                                onUpdate: () => this.updateSymbols(),
                                onComplete: () => {
                                    this.reelOverrides.clear();
                                    this.currentResultIndex = result || 0;
                                    this.updateSymbols();

                                    this.setProps(
                                        {
                                            result,
                                            state: "finished",
                                        }
                                    );
                                },
                            }
                        );

                        gsap.to(
                            // eslint-disable-next-line @typescript-eslint/unbound-method
                            this.blurFilter.velocity,
                            {
                                y: 0,
                                duration,
                                ease: "power2.out",
                                onComplete: () => {
                                    // this.spinningSound.stop();
                                    // this.spinningSound.seek(0);

                                    AudioHelper.playAudio(
                                        this.reelLandingSound,
                                        this.index
                                    );
                                    this.container.filters = [];
                                    resolve();
                                },
                            }
                        );
                    },
                    delay * 1000
                );
            }
        );
    }

    public finish(paylines: Array<[TSymbols, number]>, scoreSymbolIndexes: number[], pickSymbolIndexes: number[]): Promise<void> {
        this.setProps(
            {
                paylines,
                score: scoreSymbolIndexes,
                picks: pickSymbolIndexes,
                state: "result",
            }
        );

        return new Promise(
            (resolve) => {
                setTimeout(
                    () => {
                        for (const payline of paylines) {
                            const visibleSymbol = this.visibleSymbols.get(payline[1]);
                            if (visibleSymbol) {
                                visibleSymbol[1]?.setProps({ state: "active" });
                            }
                        }

                        if (this.props.result != null) {
                            const visibleSymbols = [ ...this.visibleSymbols.values() ];
                            for (let i = 0; i < this.reels.windowsSize; i++) {
                                if (this.props.score?.includes(this.props.result + i) && visibleSymbols.length > i) {
                                    visibleSymbols[i][1].setProps({ state: "focus" });
                                }
                            }
                        }

                        resolve();
                    },
                    300
                );
            }
        );
    }

    public activate(index: number): void {
        index = index - this.currentResultIndex;
        if (index < 0) {
            index += this.reelSymbols.length;
        }

        if (index < 0 || index >= this.reels.windowsSize) {
            return;
        }

        const visibleSymbols = [ ...this.visibleSymbols.values() ];
        visibleSymbols[index][1].setProps({ state: "normal" });
        visibleSymbols[index][1].setProps({ state: "focus" });
    }

    public skip(): void {
        if (this.isSkipping) {
            return;
        }

        this.isSkipping = true;
    }

    public reset(): void {
        this.setProps(
            {
                anticipating: [],
                paylines: [],
                result: null,
                picks: null,
                scatters: null,
                score: null,
                wilds: null,
                state: "idle",
            }
        );
    }

    protected stateChanged(props: GameReelLayerProps): void {
        if (props.state === "idle") {
            for (const symbol of this.shapes.symbols.getPool()) {
                symbol.setProps({ state: "normal" });
            }

            this.currentResultIndex = 0;
            this.isSkipping = false;
            this.updateSymbols();
        }

        if (props.state === "starting") {
            this.isSkipping = false;
            for (const symbol of this.shapes.symbols.getPool()) {
                symbol.setProps({ state: "normal" });
            }
        }

        if (props.state === "finished") {
            let scatterSymbol: TSymbols | undefined;
            let wildSymbol: TSymbols | undefined;
            for (const visibleSymbol of this.visibleSymbols.values()) {
                if (props.scatters?.includes(visibleSymbol[0])) {
                    visibleSymbol[1].setProps({ state: "focus" });
                    scatterSymbol = visibleSymbol[0];
                }

                if (props.wilds?.includes(visibleSymbol[0])) {
                    visibleSymbol[1].setProps({ state: "focus" });
                    wildSymbol = visibleSymbol[0];
                }
            }

            if (scatterSymbol) {
                const level = this.props.anticipating.find((a) => a[0] === scatterSymbol)?.[1] ?? 0;
                if (level > 0) {
                    AudioHelper.playAudio(
                        this.symbolSounds.get(scatterSymbol),
                        level - 1
                    );
                }
            }

            if (wildSymbol) {
                AudioHelper.playAudio(this.symbolSounds.get(wildSymbol));
            }
        }
    }

    protected loaded(): void {
        super.loaded();
        this.updateSymbols();
    }

    private updateSymbols() {
        if (!this.reelSymbols.length) {
            return;
        }

        const size = this.width;
        this.shapes.symbols.transaction(
            (getOne) => {
                let baseIndex = Math.round(this.currentResultIndex);
                const baseOffset = this.currentResultIndex - baseIndex;
                baseIndex = baseIndex % this.reelSymbols.length;
                const overlap = this.reels.windowsSize + 1;

                let lastSymbol: TSymbols | undefined;
                let repeating = 0;
                for (let i = -overlap; i < (this.reels.windowsSize + overlap); i++) {
                    let index = baseIndex + i;

                    index = index % this.reelSymbols.length;
                    while (index < 0) {
                        index += this.reelSymbols.length;
                    }

                    const symbol = this.reelOverrides.get(index) ?? this.reelSymbols[index];
                    const definition = this.game.symbols[symbol];
                    if (lastSymbol === symbol) {
                        repeating++;
                    } else {
                        repeating = 0;
                        lastSymbol = symbol;
                    }

                    if ((repeating % definition.heightFactor) > 0) {
                        continue;
                    }

                    const symbolLayer = getOne();
                    symbolLayer.setProps({ symbol });
                    symbolLayer.resize(size, size * definition.heightFactor);
                    symbolLayer.move(0, (i - baseOffset) * size);

                    if (i >= 0 && i < this.reels.windowsSize) {
                        for (let repeat = 0; repeat < definition.heightFactor && (i + repeat) < this.reels.windowsSize; repeat++) {
                            this.visibleSymbols.set(i + repeat, [ symbol, symbolLayer ]);
                        }
                    }
                }
            }
        );
    }

    private reelEaseInFunction: gsap.EaseFunction = (progress: number) => this.reelEaseFunction(progress / 2) * 2;
    private reelEaseOutFunction: gsap.EaseFunction = (progress: number) => (this.reelEaseFunction(0.5 + (progress / 2)) - 0.5) * 2;
}
