import * as PIXI from "pixi.js";
import * as React from "react";
import { AudioHelper } from "../../common/helpers/audio.helper";
import { AutoPlayConfigurations } from "../auto-play-modal/auto-play-modal.properties";
import { AutoPlayModal } from "../auto-play-modal/auto-play-modal.component";
import { Button } from "../button/button.component";
import { CSSTransition } from "react-transition-group";
import { DefuseGameConfiguration, DefuseGameDefinition, getDefuseGame } from "./defuse-game.constants";
import { DefuseGamePageProperties } from "./defuse-game-page.properties";
import { DefuseSlotsSimulatorService } from "../../services/slots/defuse-slots-simulator.service";
import { Footer } from "../footer/footer.component";
import { Game } from "../game/game.component";
import { GameConfiguration } from "../game/game-configuration.interface";
import { GuideModal } from "../guide-modal/guide-modal.component";
import { Home } from "../home/home.component";
import { LanguageContextProvider } from "../translate/language.context";
import { MobileControls } from "../mobile-controls/mobile-controls.component";
import { NocturneLoader } from "../nocturne-loader/nocturne-loader.component";
import { PreloadHelper, PreloadProgressEvent } from "../../common/helpers/preload.helper";
import { SlotsActionDto } from "../../services/slots/dtos/slots-action.dto";
import { SlotsActionType } from "../../services/slots/enums/slots-action-type.enum";
import { SlotsBalanceActionDto } from "../../services/slots/dtos/slots-balance-action.dto";
import { SlotsGameDto } from "../../services/slots/dtos/slots-game.dto";
import { SlotsServiceBase } from "../../services/slots/slots-service.base";
import classNames from "classnames";
import screenfull from "screenfull";
import styles from "./defuse-game-page.module.scss";

const GameAssetsProgressScale = 0.5;
const HtmlAssetsProgressScale = 0.5;

export class DefuseGamePage extends React.PureComponent<DefuseGamePageProperties, {
    matchId?: number;
    isPlaying: boolean;
    isSkipping: boolean;
    isBusy: boolean;
    isMobile?: boolean;
    hasEntered: boolean;
    balance?: number;
    view?: "loading" | "home" | "game";
    loadingPercentage?: number;
    game?: SlotsGameDto;
    configuration: GameConfiguration;
    coinValue: number;
    winnings: number;
    openModal?: "auto-play" | "guide";
    scale: number;
}> {
    private isComponentMounted = false;
    private service: SlotsServiceBase;
    private loadingCallback?: ReturnType<typeof PIXI.Loader.shared.onComplete.add>;
    private progressCallback?: ReturnType<typeof PIXI.Loader.shared.onProgress.add>;
    private rendererReference = React.createRef<Game>();
    private resizeObserver: ResizeObserver | undefined;
    private wrapperReference = React.createRef<HTMLDivElement>();
    private presets: Record<string, string> | undefined;
    private autoPlayConfigurations?: AutoPlayConfigurations;
    private game: DefuseGameConfiguration<DefuseGameDefinition>;

    public constructor(props: DefuseGamePageProperties) {
        super(props);
        this.game = getDefuseGame(props.language || "en");
        this.state = {
            isPlaying: false,
            isSkipping: false,
            isBusy: !props.isDemo,
            isMobile: undefined,
            hasEntered: false,
            balance: props.isDemo ? 10000 : undefined,
            loadingPercentage: 0,
            coinValue: 0.01,
            winnings: 0,
            scale: 1,
            configuration: {
                actions: {
                    "spin": this.onSpinClicked,
                    "toggle-auto-play": this.onAutoPlayToggled,
                    "toggle-fast-mode": this.onFastModeToggled,
                    "min-bet": this.onMinBetClicked,
                    "max-bet": this.onMaxBetClicked,
                    "decrease-bet": this.onDecreaseBetAmountClicked,
                    "increase-bet": this.onIncreaseBetAmountClicked,
                },
                autoPlayRounds: null,
                isFastModeActive: false,
                balance: props.isDemo ? 10000 : 0,
                totalBetAmount: 0.01 * this.game.paylines,
                winnings: 0,
                pick: this.onPickClicked,
                currency: "$",
            },
        };

        this.service = props.isDemo ? new DefuseSlotsSimulatorService() : this.props.slotsService;
        if (this.service.getPresets) {
            this.presets = this.service.getPresets();
        }
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;

        this.loadingCallback = PIXI.Loader.shared.onComplete.add(this.onGameAssetsLoaded);
        this.progressCallback = PIXI.Loader.shared.onProgress.add(this.onGameAssetsLoading);
        PreloadHelper.addEventListener(this.onPreloadAssetsLoading);

        this.onPageLoaded();
        this.componentDidUpdate({ slotsService: this.props.slotsService }, this.state);
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;

        this.resizeObserver?.disconnect();

        if (this.loadingCallback) {
            PIXI.Loader.shared.onComplete.detach(this.loadingCallback);
        }

        if (this.progressCallback) {
            PIXI.Loader.shared.onProgress.detach(this.progressCallback);
        }
    }

    public componentDidUpdate(oldProps: DefuseGamePageProperties, oldState: DefuseGamePage["state"]): void {
        if (!this.resizeObserver && this.wrapperReference.current) {
            this.resizeObserver = new ResizeObserver(this.onResize);
            this.resizeObserver.observe(this.wrapperReference.current);
            this.onResize();
        } else if (this.state.isMobile !== oldState.isMobile) {
            this.onResize();
        }

        if (
            this.props.balance != null &&
            this.state.balance == null
        ) {
            this.setState(
                {
                    balance: this.props.balance,
                    isBusy: false,
                    configuration: {
                        ...this.state.configuration,
                        balance: this.props.balance,
                    },
                }
            );

            this.rendererReference.current?.reset();
        }

        if (this.props.currency !== oldProps.currency) {
            this.setState(
                {
                    configuration: {
                        ...this.state.configuration,
                        currency: this.props.currency ?? "$",
                    },
                }
            );
        }
    }

    public render(): React.ReactNode {
        if (this.state.isMobile === undefined) {
            return null;
        }

        return <LanguageContextProvider value={{ language: this.props.language || "en" }}>
            <article
                ref={this.wrapperReference}
                className={
                    classNames(
                        styles.defuse,
                        {
                            [styles.mobile]: this.state.isMobile,
                            [styles.entered]: this.state.hasEntered,
                            [styles.game]: this.state.view === "game",
                        }
                    )
                }
                style={
                    {
                        ["--scale" as any]: this.state.scale.toFixed(2),
                    }
                }
            >
                <CSSTransition
                    classNames="fade"
                    timeout={300}
                    in={this.state.view !== null && this.state.view !== "game"}
                    unmountOnExit={false}
                >
                    <div className={styles.splash}>
                        <NocturneLoader
                            isLoading={this.state.view === "loading"}
                            isLoaded={this.state.loadingPercentage == null}
                            isMobile={this.state.isMobile}
                            percentage={this.state.loadingPercentage ?? 100}
                            onExit={this.onLoaderExit}
                        />
                        {
                            this.game.homePage && <Home
                                background={this.game.homePage.background}
                                slides={this.state.isMobile ? this.game.homePage.mobileSlides : this.game.homePage.desktopSlides}
                                isReady={this.state.loadingPercentage == null && !this.state.isBusy}
                                isMobile={this.state.isMobile}
                                onEnter={this.onEnterClicked}
                            />
                        }
                    </div>
                </CSSTransition>
                {!!this.state.view && this.renderGame()}
                <div className={styles.overlays}>
                    <CSSTransition
                        classNames="fade"
                        timeout={300}
                        in={this.state.openModal === "auto-play"}
                        unmountOnExit={false}
                    >
                        <AutoPlayModal
                            configurations={this.autoPlayConfigurations}
                            autoRounds={this.state.configuration.autoPlayRounds ?? undefined}
                            balance={this.state.balance}
                            isMobile={this.state.isMobile}
                            onExit={this.onCloseModal}
                            onUpdate={this.onAutoPlayConfigurationsChanged}
                        />
                    </CSSTransition>
                    <CSSTransition
                        classNames="fade"
                        timeout={300}
                        in={this.state.openModal === "guide"}
                        unmountOnExit={false}
                    >
                        <GuideModal
                            slides={this.state.isMobile ? this.game.guide.mobileSlides : this.game.guide.desktopSlides}
                            isMobile={this.state.isMobile}
                            onExit={this.onCloseModal}
                        />
                    </CSSTransition>
                </div>
            </article>
        </LanguageContextProvider>;
    }

    private renderGame() {
        return <>
            <main className={styles.renderer} style={this.props.style}>
                <Game
                    defaultBoard={"main"}
                    game={this.state.isMobile ? this.game.mobileGame : this.game.desktopGame}
                    configuration={this.state.configuration}
                    isReady={!this.state.isBusy && !this.state.openModal}
                    ref={this.rendererReference}
                />
            </main>
            <Footer
                isDemo={this.props.isDemo}
                currency={this.props.currency}
                balance={this.state.balance}
                totalBetAmount={this.state.coinValue * this.game.paylines}
                winAmount={this.state.winnings}
                autoRounds={this.state.configuration.autoPlayRounds ?? undefined}
                isBusy={this.state.isBusy || this.state.isPlaying || !!this.state.matchId}
                onHomeClicked={this.onHomeClicked}
                onInfoClicked={this.onInfoClicked}
                isMobile={this.state.isMobile}
            />
            {
                !!this.state.isMobile && <MobileControls
                    {...this.state.configuration}
                    isBusy={this.state.isBusy || this.state.isPlaying || !!this.state.matchId || !!this.state.openModal}
                    isSkipping={(this.state.isPlaying || !!this.state.matchId) && this.state.isSkipping}
                    isPlaying={this.state.isPlaying || !!this.state.matchId}
                />
            }
            {
                !!this.presets && <div className={styles.debug}>
                    <ul>
                        {
                            Object.entries(this.presets)
                                .map(
                                    (p) => <li key={p[0]}>
                                        <Button
                                            isDisabled={this.state.isBusy || this.state.isPlaying || !!this.state.matchId || !!this.state.openModal}
                                            onClick={() => this.onSpinClicked(p[0])}
                                            style={this.state.isMobile ? "normal" : "game"}
                                        >{p[1]}</Button>
                                    </li>
                                )
                        }
                    </ul>
                </div>
            }
        </>;
    }

    private onResize = () => {
        setTimeout(
            () => {
                if (!this.wrapperReference.current) {
                    return;
                }

                let scalingWidth = 1800;
                let scalignHeight = 1200;

                if (this.state.isMobile) {
                    if (window.innerHeight > window.innerWidth) {
                        // portrait
                        scalingWidth = 540;
                        scalignHeight = 960;
                    } else {
                        // landscape
                        scalingWidth = 960;
                        scalignHeight = 540;
                    }
                }

                const width = this.wrapperReference.current.clientWidth;
                const height = this.wrapperReference.current.clientHeight;
                this.setState(
                    {
                        scale: Math.min(width / scalingWidth, height / scalignHeight, 1),
                    }
                );
            },
            200
        );
    };

    private onGameAssetsLoading = (loader: PIXI.Loader) => {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState(
            {
                loadingPercentage: loader.progress * GameAssetsProgressScale,
            }
        );
    };

    private onPreloadAssetsLoading = (progress: PreloadProgressEvent) => {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState(
            {
                loadingPercentage: (GameAssetsProgressScale * 100) + (progress.progress * HtmlAssetsProgressScale),
            },
            () => {
                if (progress.completed) {
                    this.onPreloadAssetsLoaded();
                }
            }
        );
    };

    private onGameAssetsLoaded = () => {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState(
            {
                loadingPercentage: GameAssetsProgressScale * 100,
            }
        );

        void PreloadHelper.load();
    };

    private onPreloadAssetsLoaded = () => {
        if (!this.isComponentMounted) {
            return;
        }

        this.setState(
            {
                loadingPercentage: undefined,
            }
        );
    };

    private onLoaderExit = () => {
        if (this.state.loadingPercentage != null || !this.rendererReference.current) {
            return;
        }

        AudioHelper.unlock();
        this.onResize();
        this.enforceFullscreen();

        if (!!this.game.homePage) {
            this.setState(
                {
                    view: "home",
                }
            );
        } else {
            this.onEnterClicked();
        }
    };

    private onEnterClicked = () => {
        if (!this.rendererReference.current) {
            return;
        }

        this.setState(
            {
                view: "game",
            }
        );

        if (!this.state.hasEntered) {
            setTimeout(
                () => {
                    if (!this.rendererReference.current) {
                        return;
                    }

                    this.rendererReference.current.enter()
                        .then(
                            () => {
                                if (!this.isComponentMounted) {
                                    return;
                                }

                                this.setState(
                                    {
                                        view: "game",
                                        hasEntered: true,
                                    }
                                );
                            },
                            () => undefined
                        );
                },
                300
            );
        }
    };

    private onMaxBetClicked = () => {
        this.setBetAmount(1);
    };

    private onMinBetClicked = () => {
        this.setBetAmount(0.01);
    };

    private onDecreaseBetAmountClicked = () => {
        const preset = [ 0.01, 0.02, 0.05, 0.10, 0.20, 0.50, 1 ].reverse().find((e) => e < this.state.coinValue) ?? 0.01;
        this.setBetAmount(preset);
    };

    private onIncreaseBetAmountClicked = () => {
        const preset = [ 0.01, 0.02, 0.05, 0.10, 0.20, 0.50, 1 ].find((e) => e > this.state.coinValue) ?? 1;
        this.setBetAmount(preset);
    };

    private setBetAmount(amount: number) {
        this.setState(
            {
                coinValue: amount,
                configuration: {
                    ...this.state.configuration,
                    totalBetAmount: amount * this.game.paylines,
                },
            }
        );
    }

    private onAutoPlayToggled = () => {
        if (!!this.state.configuration.autoPlayRounds) {
            this.onAutoPlayDisabled();
        } else {
            this.setState(
                {
                    openModal: "auto-play",
                }
            );
        }
    };

    private onAutoPlayConfigurationsChanged = (configurations: AutoPlayConfigurations, rounds?: number) => {
        this.autoPlayConfigurations = configurations;
        this.setState(
            {
                winnings: 0,
                configuration: {
                    ...this.state.configuration,
                    autoPlayRounds: rounds || null,
                },
            }
        );
    };

    private onCloseModal = () => {
        this.setState(
            {
                openModal: undefined,
            }
        );
    };

    private onAutoPlayDisabled = () => {
        this.setState(
            {
                configuration: {
                    ...this.state.configuration,
                    autoPlayRounds: null,
                },
            },
            () => {
                this.autoPlayConfigurations = undefined;
            }
        );
    };

    private onFastModeToggled = () => {
        this.setState(
            {
                configuration: {
                    ...this.state.configuration,
                    isFastModeActive: !this.state.configuration.isFastModeActive,
                },
            }
        );
    };

    private onPageLoaded = () => {
        this.setState(
            {
                view: "loading",
                isMobile: (typeof window !== "undefined" && window.innerWidth <= 900 && window.innerHeight <= 900) ||
                    (typeof localStorage !== "undefined" && !!(localStorage as Record<string, unknown>).mobile) ||
                    (typeof window !== "undefined" && window.navigator.maxTouchPoints > 1),
            },
            () => {
                this.onResize();
            }
        );
    };

    private onHomeClicked = () => {
        try {
            const lobbyUrl = new URLSearchParams(window.location.search).get("lobby");
            if (lobbyUrl) {
                window.location.replace(lobbyUrl);
                return;
            }
        } catch (error) {
            // ignore
        }

        if (!!this.game.homePage) {
            this.setState(
                {
                    view: "home",
                }
            );
        }
    };

    private onBalanceUpdated = (action: SlotsBalanceActionDto) => {
        this.setState<"balance" | "configuration">(
            {
                balance: action.balance,
                ...(
                    action.winnings != null ? {
                        winnings: this.state.winnings + action.winnings,
                    } : {}
                ),

                configuration: {
                    ...this.state.configuration,
                    balance: action.balance,
                    ...(
                        action.winnings != null ? {
                            winnings: this.state.winnings + action.winnings,
                        } : {}
                    ),
                },
            }
        );
    };

    private onInfoClicked = () => {
        this.setState(
            {
                openModal: "guide",
            }
        );
    };

    private onSpinClicked = (preset?: string) => {
        if (!this.rendererReference.current) {
            return;
        }

        if (this.state.isPlaying) {
            if (!this.state.isSkipping) {
                this.rendererReference.current?.skip();
                this.setState(
                    {
                        isSkipping: true,
                    }
                );
            }
            return;
        }

        void this.onSpin(preset);
    };

    private onSpin = async (preset?: string) => {
        if (!this.rendererReference.current || !!this.state.matchId) {
            return;
        }

        if (this.state.isPlaying) {
            return;
        }

        this.setState(
            {
                isPlaying: true,
                game: undefined,
                winnings: !!this.state.configuration.autoPlayRounds ? this.state.winnings : 0,
                configuration: {
                    ...this.state.configuration,
                    autoPlayRounds: !!this.state.configuration.autoPlayRounds ? ((this.state.configuration.autoPlayRounds - 1) || null) : null,
                },
            }
        );

        const amount = Math.trunc(this.state.coinValue * 100) / 100;
        if (this.state.balance != null) {
            this.onBalanceUpdated(
                {
                    balance: this.state.balance - (amount * this.game.paylines),
                }
            );
        }

        try {
            const responsePromise = this.service.spin(
                {
                    amount,
                    preset,
                }
            );

            const [ _, response ] = await Promise.all(
                [
                    this.rendererReference.current.initiate(),
                    responsePromise,
                ]
            );

            if (!this.isComponentMounted || !response) {
                return;
            }

            this.setState(
                {
                    game: response,
                }
            );

            for (const action of response.actions) {
                await this.rendererReference.current.apply([ action ]);

                if (action.type === SlotsActionType.Balance) {
                    this.onBalanceUpdated((action as (SlotsActionDto<SlotsActionType.Balance>)).payload);
                }
            }

            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    matchId: !response.isFinished ? response.matchId : undefined,
                    isPlaying: false,
                    isSkipping: false,
                    balance: response.finalBalance,
                },
                () => {
                    if (!response.isFinished) {
                        return;
                    }

                    if (this.shouldAutoPlay(response)) {
                        setTimeout(() => void this.onSpinClicked(), 0);
                    } else {
                        this.onAutoPlayDisabled();
                        this.rendererReference.current?.reset();
                    }
                }
            );
        } catch (error) {
            if (!this.isComponentMounted) {
                return;
            }

            this.rendererReference.current.reset();

            this.setState(
                {
                    matchId: undefined,
                    isPlaying: false,
                    isSkipping: false,
                }
            );
        }
    };

    private onPickClicked = (reel: number, index: number) => {
        if (!this.rendererReference.current) {
            return;
        }

        if (this.state.isPlaying) {
            return;
        }

        void this.onPick(reel, index);
    };

    private onPick = async (reel: number, index: number) => {
        if (!this.rendererReference.current || !this.state.matchId) {
            return;
        }

        if (this.state.isPlaying) {
            return;
        }

        this.setState(
            {
                isPlaying: true,
                game: undefined,
            }
        );

        try {
            const response = await this.service.pick(
                {
                    matchId: this.state.matchId,
                    index,
                    reel,
                }
            );

            if (!this.isComponentMounted || !response) {
                return;
            }

            this.setState(
                {
                    game: response,
                }
            );

            for (const action of response.actions) {
                await this.rendererReference.current.apply([ action ]);

                if (action.type === SlotsActionType.Balance) {
                    this.onBalanceUpdated((action as (SlotsActionDto<SlotsActionType.Balance>)).payload);
                }
            }

            if (!this.isComponentMounted) {
                return;
            }

            this.setState(
                {
                    matchId: !response.isFinished ? response.matchId : undefined,
                    isPlaying: false,
                    isSkipping: false,
                    balance: response.finalBalance,
                },
                () => {
                    if (!response.isFinished) {
                        return;
                    }

                    if (this.shouldAutoPlay(response)) {
                        setTimeout(() => void this.onSpinClicked(), 0);
                    } else {
                        this.onAutoPlayDisabled();
                        this.rendererReference.current?.reset();
                    }
                }
            );
        } catch (error) {
            if (!this.isComponentMounted) {
                return;
            }

            this.rendererReference.current.reset();

            this.setState(
                {
                    matchId: undefined,
                    isPlaying: false,
                    isSkipping: false,
                }
            );
        }
    };

    private shouldAutoPlay(response: SlotsGameDto) {
        if (this.state.configuration.autoPlayRounds == null || this.state.configuration.autoPlayRounds <= 0) {
            return false;
        }

        const balance = response.finalBalance;
        if (this.autoPlayConfigurations?.stopOnMinimumBalance != null && balance <= this.autoPlayConfigurations.stopOnMinimumBalance) {
            return false;
        }

        if (this.autoPlayConfigurations?.stopOnMaximumBalance != null && balance >= this.autoPlayConfigurations.stopOnMaximumBalance) {
            return false;
        }

        if (this.autoPlayConfigurations?.shouldStopWhenBoardChanges && response.actions.some((a) => a.type === SlotsActionType.Board)) {
            return false;
        }

        if (this.autoPlayConfigurations?.shouldStopWhenPickEmHappened && response.actions.some((a) => a.type === SlotsActionType.Pick)) {
            return false;
        }

        if (this.autoPlayConfigurations?.stopOnWinning != null) {
            const winning = response.actions
                .filter((a) => a.type === SlotsActionType.Balance)
                .map((a) => (a as SlotsActionDto<SlotsActionType.Balance>).payload.winnings ?? 0)
                .reduce<number>((a, b) => Math.max(a, b), 0);
            if (winning > this.autoPlayConfigurations.stopOnWinning) {
                return false;
            }
        }

        return true;
    }

    private enforceFullscreen() {
        if (typeof window === "undefined" || !this.state.isMobile || !screenfull.isEnabled) {
            return;
        }

        void screenfull.request(window.document.body);
    }
}
