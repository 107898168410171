import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { GameBoardDefinition } from "../../game.properties";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameImageLayer } from "../image/game-image.layer";
import { GamePaylineLayerProps as GamePaylineLayerProps } from "./game-payline-layer.props";
import { PixiJSLayerPool } from "../../../../common/utilities/pixijs-layer-pool";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import gsap from "gsap";

export class GamePaylineLayer extends PixiJSRenderingLayer<
GameConfiguration, {
    lines: PixiJSLayerPool<GameConfiguration, GameImageLayer>;
    stationaryBall: PixiJSLayerPool<GameConfiguration, GameImageLayer>;
    movingBall: PixiJSLayerPool<GameConfiguration, GameImageLayer>;
},
"",
GamePaylineLayerProps,
PIXI.Container
> {
    private mask = new PIXI.Graphics();
    private maskVisibility = 0;
    private sound: Audio[] = [];
    private isSkipping = false;
    private numberOfReels = 0;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly board: GameBoardDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "idle",
                activePaylines: [],
            },
            {
                lines: new PixiJSLayerPool(
                    () => new GameImageLayer(
                        configuration,
                        app,
                        {
                            ...board.payline,
                            width: undefined,
                            height: undefined,
                            paddingX: undefined,
                            paddingY: undefined,
                        }
                    )
                ),
                movingBall: new PixiJSLayerPool(
                    () => new GameImageLayer(
                        configuration,
                        app,
                        board.payline.fireball
                    )
                ),
                stationaryBall: new PixiJSLayerPool(
                    () => new GameImageLayer(
                        configuration,
                        app,
                        board.payline.fireball
                    )
                ),
            }
        );

        this.numberOfReels = Object.keys(board.reels.symbols).length;
        this.container.alpha = 0;
        this.container.addChild(this.mask);
        this.sound = AudioHelper.getAudioList(board.payline.sound);
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        this.shapes.lines.resize(width, height);

        const factor = (height / this.board.reels.windowsSize) / this.board.reels.size;

        this.mask.scale.x = this.shapes.lines.container.scale.x;
        this.mask.scale.y = this.shapes.lines.container.scale.y;
        this.mask.x = 0;
        this.mask.y = 0;

        this.shapes.stationaryBall.resize(
            (this.board.payline.fireball.width ?? 0) * factor,
            (this.board.payline.fireball.height ?? 0) * factor
        );

        this.shapes.movingBall.resize(
            (this.board.payline.fireball.width ?? 0) * factor,
            (this.board.payline.fireball.height ?? 0) * factor
        );

        const coordinates = this.getCoordinates();
        this.updateSegments(coordinates);
        this.updateMask(coordinates);
    }

    public hide(): Promise<void> {
        this.setProps(
            {
                state: "idle",
            }
        );

        return this.delay(200);
    }

    public show(paylines: number[][]): Promise<void> {
        this.setProps(
            {
                state: "active",
                activePaylines: paylines,
            }
        );

        if (this.configuration.isFastModeActive || this.isSkipping) {
            return this.delay(0.3 * 1000);
        }

        return this.delay((this.board.payline.delay + 0.3) * 1000);
    }

    public skip(): void {
        if (this.isSkipping) {
            return;
        }

        this.isSkipping = true;
    }

    protected loaded(): void {
        super.loaded();

        this.shapes.lines.container.mask = this.mask;
        const coordinates = this.getCoordinates();
        this.updateSegments(coordinates);
        this.updateMask(coordinates);
    }

    protected stateChanged(props: GamePaylineLayerProps): void {
        if (props.state === "idle") {
            this.isSkipping = false;
            gsap.to(
                this.container,
                {
                    alpha: 0,
                    duration: 0.2,
                    ease: "power1.in",
                    onComplete: () => {
                        this.maskVisibility = 0;
                        this.updateMask([], true);
                        this.container.renderable = false;
                    },
                }
            );
        } else {
            const coordinates = this.getCoordinates();
            this.updateSegments(coordinates);
            this.container.renderable = true;

            // refresh mask
            this.maskVisibility = 0;
            this.mask.width = this.width;
            this.mask.height = this.height;
            this.mask.x = 0;
            this.mask.y = 0;

            if (this.isSkipping || this.configuration.isFastModeActive) {
                this.maskVisibility = 1;
                this.container.alpha = 0;
                this.updateMask(coordinates, false);

                gsap.to(
                    this.container,
                    {
                        alpha: 1,
                        duration: 0.2,
                        ease: "power1.in",
                    }
                );
            } else {
                this.container.alpha = 1;
                gsap.fromTo(
                    this,
                    {
                        maskVisibility: 0,
                    },
                    {
                        maskVisibility: 1,
                        duration: 0.3,
                        ease: "power1.in",
                        onUpdate: () => {
                            this.updateMask(coordinates, false);
                        },
                    }
                );
            }

            AudioHelper.playAudio(this.sound);
        }
    }

    private delay(delay: number): Promise<void> {
        return new Promise(
            (resolve) => {
                setTimeout(resolve, delay);
            }
        );
    }

    private updateMask(paylineCoordinates: Array<Array<[number, number]>>, clear = true) {
        if (clear) {
            this.mask.clear();
        }

        this.mask.beginFill(0x000000);
        this.mask.drawRect(0, 0, this.width * this.maskVisibility, this.height);
        this.mask.endFill();

        const balls = this.shapes.movingBall.getPool();
        for (let i = 0; i < paylineCoordinates.length; i++) {
            const coordinates = paylineCoordinates[i];

            let prevX: number | undefined;
            let prevY: number | undefined;
            let finalY: number | undefined;
            for (const coordinate of coordinates) {
                const x = coordinate[0] / this.numberOfReels;
                const y = coordinate[1] / this.board.reels.windowsSize;

                if (x >= this.maskVisibility) {
                    const finalDeltaX = (prevX ?? x) - this.maskVisibility;
                    const deltaX = x - (prevX ?? x);
                    const deltaY = y - (prevY ?? y);
                    finalY = (prevY ?? y) - ((deltaY / deltaX) * finalDeltaX);

                    break;
                }

                prevX = x;
                prevY = y;
            }

            if (finalY == null) {
                finalY = prevY;
            }

            if (finalY != null) {
                balls[i].move(
                    this.width * this.maskVisibility,
                    this.height * finalY
                );
            }
        }
    }

    private getCoordinates(): Array<Array<[number, number]>> {
        const activePaylines = this.props.activePaylines ?? [];
        const coordinates: Array<Array<[number, number]>> = [];

        for (const activePayline of activePaylines) {
            const paylineCoordinates: Array<[number, number]> = [];
            for (let i = 0; i < activePayline.length; i++) {
                const current = activePayline[i];
                const prev = i > 0 ? activePayline[i - 1] : undefined;
                const next = i < (activePayline.length - 1) ? activePayline[i + 1] : undefined;

                // first point, lets fill prev
                if (prev == null) {
                    // exit with continuing the line
                    // coordinates.push([ (-1 / 2) + i, (1 / 2) + (current - (next ?? 0)) + current ]);

                    // exit straight
                    paylineCoordinates.push([ (-1 / 2) + i, (1 / 2) + current ]);
                }

                paylineCoordinates.push([ (1 / 2) + i, (1 / 2) + current ]);

                // last point, lets fill next
                if (next == null) {
                    // exit with continuing the line
                    // coordinates.push([(3 / 2) + i, (1 / 2) + (current - (prev ?? 0)) + current]);

                    // exit straight
                    paylineCoordinates.push([ (3 / 2) + i, (1 / 2) + current ]);
                }
            }

            coordinates.push(paylineCoordinates);
        }

        return coordinates;
    }

    private updateSegments(paylinesCoordinates: Array<Array<[number, number]>>) {
        const sizeX = this.width / this.numberOfReels;
        const sizeY = this.height / this.board.reels.windowsSize;
        const factor = sizeY / this.board.reels.size;
        const offsetX = 0;
        const offsetY = 0;
        const firstYs: number[] = [];
        const lastYs: number[] = [];
        this.shapes.lines.transaction(
            (getOne) => {
                const maxDistance = (this.board.payline.maxWidth || this.board.payline.width) * factor;
                for (const paylineCoordinates of paylinesCoordinates) {
                    let firstY: number | undefined;
                    let lastY: number | undefined;
                    for (let i = 1; i < paylineCoordinates.length; i++) {
                        let p1X = (paylineCoordinates[i - 1][0] * sizeX) + offsetX;
                        let p1Y = (paylineCoordinates[i - 1][1] * sizeY) + offsetY;
                        const p2X = (paylineCoordinates[i][0] * sizeX) + offsetX;
                        const p2Y = (paylineCoordinates[i][1] * sizeY) + offsetY;

                        if (firstY == null) {
                            firstY = p1Y;
                        }

                        lastY = p2Y;

                        const rotation = Math.atan2(p2Y - p1Y, p2X - p1X);
                        const totalWidth = Math.sqrt(Math.pow(p2X - p1X, 2) + Math.pow(p2Y - p1Y, 2));
                        const segments = Math.ceil(totalWidth / maxDistance);
                        const segmentFactor = (totalWidth / segments) / this.board.payline.width;
                        const segmentWidth = (this.board.payline.width + (this.board.payline.paddingX * 2)) * segmentFactor;
                        const segmentHeight = (this.board.payline.height + (this.board.payline.paddingY * 2)) * segmentFactor;

                        for (let s = 0; s < segments; s++) {
                            const section = getOne();

                            section.resize(segmentWidth, segmentHeight);
                            section.move(p1X, p1Y);
                            section.rotate(rotation);

                            p1X += (p2X - p1X) / (segments - s);
                            p1Y += (p2Y - p1Y) / (segments - s);
                        }
                    }

                    if (firstY != null) {
                        firstYs.push(firstY);
                    }

                    if (lastY != null) {
                        lastYs.push(lastY);
                    }
                }
            }
        );

        this.shapes.stationaryBall.transaction(
            (getOne) => {
                for (const firstY of firstYs) {
                    const ball = getOne();
                    ball.move(0, firstY);
                }
            }
        );

        this.shapes.movingBall.transaction(
            (getOne) => {
                for (const lastY of lastYs) {
                    const ball = getOne();
                    ball.move(0, lastY);
                }
            }
        );
    }
}
