import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { GameBoardLayer } from "../board/game-board.layer";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameControlsLayer } from "../controls/game-controls.layer";
import { GameDefinition, GameTransitionDefinition } from "../../game.properties";
import { GameImageLayer } from "../image/game-image.layer";
import { GameMainLayerProps } from "./game-main-layer.props";
import { GameOverlayLayer } from "../overlay/game-overlay.layer";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { SlotsActionDto } from "../../../../services/slots/dtos/slots-action.dto";
import { SlotsActionType } from "../../../../services/slots/enums/slots-action-type.enum";
import { SlotsBalanceActionDto } from "../../../../services/slots/dtos/slots-balance-action.dto";
import { SlotsBalanceActionType } from "../../../../services/slots/enums/slots-balance-action-type.enum";
import { SlotsBoardActionDto } from "../../../../services/slots/dtos/slots-board-action.dto";
import { SlotsDelayActionDto } from "../../../../services/slots/dtos/slots-delay-action.dto";
import { SlotsOverlayActionDto } from "../../../../services/slots/dtos/slots-overlay-action.dto";
import { SlotsPickActionDto } from "../../../../services/slots/dtos/slots-pick-action.dto";
import { SlotsSpinActionDto } from "../../../../services/slots/dtos/slots-spin-action.dto";
import gsap from "gsap";

type BoardIndex<TBoards extends string = string> = `Board_${TBoards}`;

type BackgroundImageIndex<TBoards extends string = string> = `Background_${TBoards}`;
type BackgroundMusicIndex<TBoards extends string = string> = `Background_${TBoards}`;

type OverlayIndex<TOverlays extends string = string> = `Overlay_${TOverlays}`;

type TransitionIndex<TBoards extends string = string> = `Transition_${TBoards}_In` | `Transition_${TBoards}_Out`;

export class GameMainLayer<
    TSymbols extends string = string,
    TBoards extends string = string,
    TOverlays extends string = string,
    TReels extends number = 1
> extends PixiJSRenderingLayer<
    GameConfiguration,
    {
        logo?: GameImageLayer;
        controls?: GameControlsLayer;
    } & {
        [K in BoardIndex<TBoards>]: GameBoardLayer<TSymbols, TReels>;
    } & {
        [K in BackgroundImageIndex<TBoards>]?: GameImageLayer;
    } & {
        [K in OverlayIndex<TOverlays>]?: GameOverlayLayer;
    } & {
        [K in TransitionIndex<TBoards>]?: GameOverlayLayer;
    },
    "",
    GameMainLayerProps<TBoards>,
    PIXI.Container
    > {
    private currentBoardName: TBoards | undefined;
    private musics: Map<BackgroundMusicIndex, Audio[]>;
    private enterAudio: Audio[];
    private isSkipping = false;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly game: GameDefinition,
        defaultBoard: TBoards
    ) {
        super(
            configuration,
            app,
            app.stage,
            {
                state: "finished",
                defaultBoard,
            },
            {
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.boards)
                                    .map(
                                        (k) => (
                                            [
                                                `Background_${k}` as BackgroundImageIndex,
                                                new GameImageLayer(
                                                    configuration,
                                                    app,
                                                    game.boards[k as TBoards].background.image
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.boards)
                                    .map(
                                        (k) => (
                                            [
                                                `Board_${k}` as BoardIndex,
                                                new GameBoardLayer<TSymbols, TReels>(
                                                    configuration,
                                                    app,
                                                    game,
                                                    game.boards[k as TBoards]
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.overlays)
                                    .filter((k) => !!game.overlays[k as TOverlays] && !game.overlays[k as TOverlays]?.fullscreen)
                                    .map(
                                        (k) => (
                                            [
                                                `Overlay_${k}` as OverlayIndex,
                                                new GameOverlayLayer(
                                                    configuration,
                                                    app,
                                                    game.overlays[k as TOverlays]!
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                logo: !!game.logo ? new GameImageLayer(
                    configuration,
                    app,
                    game.logo
                ) : undefined,
                controls: !!game.controls ? new GameControlsLayer(
                    configuration,
                    app,
                    game.controls
                ) : undefined,
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.overlays)
                                    .filter((k) => !!game.overlays[k as TOverlays] && !!game.overlays[k as TOverlays]?.fullscreen)
                                    .map(
                                        (k) => (
                                            [
                                                `Overlay_${k}` as OverlayIndex,
                                                new GameOverlayLayer(
                                                    configuration,
                                                    app,
                                                    game.overlays[k as TOverlays]!
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.boards)
                                    .filter((k) => !!game.boards[k as TBoards] && !!game.boards[k as TBoards]?.out)
                                    .map(
                                        (k) => (
                                            [
                                                `Transition_${k}_Out` as OverlayIndex,
                                                new GameOverlayLayer(
                                                    configuration,
                                                    app,
                                                    game.boards[k as TBoards].out!
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(game.boards)
                                    .filter((k) => !!game.boards[k as TBoards] && !!game.boards[k as TBoards]?.in)
                                    .map(
                                        (k) => (
                                            [
                                                `Transition_${k}_In` as OverlayIndex,
                                                new GameOverlayLayer(
                                                    configuration,
                                                    app,
                                                    game.boards[k as TBoards].in!
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
            }
        );

        this.enterAudio = AudioHelper.getAudioList(this.game.sounds.enter);
        this.musics = new Map<BackgroundMusicIndex, Audio[]>(
            [
                ...(
                    Object
                        .keys(game.boards)
                        .filter((k) => !!game.boards[k as TBoards].background.music)
                        .map(
                            (k) => (
                                [
                                    `Background_${k}` as BackgroundMusicIndex,
                                    AudioHelper.getAudioList(game.boards[k as TBoards].background.music),
                                ]
                            )
                        )
                ),
            ] as Array<[BackgroundMusicIndex, Audio[]]>
        );

        for (const [ _, board ] of this.getBoards()) {
            board.container.visible = false;
            board.container.renderable = false;
        }

        for (const [ boardName, background ] of this.getBackgrounds()) {
            if (boardName === this.props.defaultBoard) {
                background.container.visible = true;
                background.container.renderable = true;
                background.container.alpha = 1;
                background.setProps({ state: "playing" });
            } else {
                background.container.visible = false;
                background.container.renderable = false;
                background.container.alpha = 0;
            }
        }

        if (this.shapes.controls) {
            this.shapes.controls.container.visible = false;
            this.shapes.controls.container.renderable = false;
            this.shapes.controls.container.alpha = 0;
        }

        if (this.shapes.logo) {
            this.shapes.logo.container.visible = false;
            this.shapes.logo.container.renderable = false;
            this.shapes.logo.container.alpha = 0;
        }
    }

    public skip(): void {
        if (this.isSkipping || this.configuration.isFastModeActive) {
            return;
        }
        this.isSkipping = true;

        this.getBoard()?.skip();
        void Promise.all(this.getOverlays().map((m) => m.hide()));
        this.shapes.controls?.setProps({ state: "skipping" });
    }

    public ready(): void {
        this.shapes.controls?.setProps({ state: "enabled" });
    }

    public busy(): void {
        this.shapes.controls?.setProps({ state: "disabled" });
    }

    public resize(width: number, height: number): void {
        width = width ?? (this.container.width || 640);
        height = height ?? (this.container.height || 480);
        super.resize(width, height);

        for (const key in this.shapes) {
            if (!Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                continue;
            }

            const shape = (this.shapes as Record<string, PixiJSRenderingLayer<GameConfiguration>>)[key];
            shape.resize(width, height);
        }
    }

    public async initiate(): Promise<void> {
        this.isSkipping = false;
        await Promise.all(this.getOverlays().map((m) => m.hide()));
        if (this.configuration.isFastModeActive) {
            this.shapes.controls?.setProps({ state: "skipping" });
        } else {
            this.shapes.controls?.setProps({ state: "spinning" });
        }
        await this.getBoard()?.start();
    }

    public async apply(action: SlotsActionDto): Promise<void> {
        switch (action.type) {
            case SlotsActionType.Delay:
                const delayPayload = action.payload as SlotsDelayActionDto;
                await new Promise((resolve) => setTimeout(resolve, delayPayload.delay * 1000));
                break;
            case SlotsActionType.Board:
                const boardPayload = action.payload as SlotsBoardActionDto<TSymbols, TBoards>;
                if (this.currentBoardName !== boardPayload.board) {
                    await this.changeBoard(boardPayload.board);
                }
                break;
            case SlotsActionType.Spin:
                const spinPayload = action.payload as SlotsSpinActionDto<TSymbols, TBoards>;
                if (this.currentBoardName !== spinPayload.board) {
                    await this.changeBoard(spinPayload.board);
                }

                this.setProps(
                    {
                        state: "spinning",
                    }
                );

                if (
                    this.getBoard()?.getProps().state !== "spinning" &&
                        this.getBoard()?.getProps().state !== "starting"
                ) {
                    await this.getBoard()?.start();
                }

                await this.getBoard()?.stop(spinPayload);
                await this.getBoard()?.finish(spinPayload);

                break;
            case SlotsActionType.Overlay:
                const overlayAction = action.payload as SlotsOverlayActionDto<TOverlays>;
                const overlay = this.getOverlay(overlayAction.overlay);
                if (overlay) {
                    await overlay.show(overlayAction.bindings);
                }
                break;
            case SlotsActionType.Balance:
                const balanceAction = action.payload as SlotsBalanceActionDto;
                if (balanceAction.type === SlotsBalanceActionType.BigWin) {
                    this.getBoard()?.bigWin();
                } else if (balanceAction.type === SlotsBalanceActionType.Win) {
                    this.getBoard()?.win();
                }
                break;
            case SlotsActionType.Pick:
                const pickAction = action.payload as SlotsPickActionDto;
                this.getBoard()?.picked(pickAction);
                break;
        }
    }

    public async enter(): Promise<void> {
        AudioHelper.playAudio(this.enterAudio);
        await this.changeBoard(this.props.defaultBoard);
        this.ready();
    }

    public showOverlay(name: TOverlays): Promise<void> {
        const overlay = this.getOverlay(name);
        if (overlay) {
            return overlay.show();
        }

        return Promise.resolve();
    }

    public hideOverlay(name: TOverlays): Promise<void> {
        const overlay = this.getOverlay(name);
        if (overlay) {
            return overlay.hide();
        }

        return Promise.resolve();
    }

    protected stateChanged(props: GameMainLayerProps<TBoards>, lastState: GameMainLayerProps["state"]): void {
        super.stateChanged(props, lastState);
    }

    protected loaded(): void {
        super.loaded();
        // void this.changeBoard(this.props.defaultBoard);
    }

    private async changeBoard(newBoardName: TBoards): Promise<void> {
        if (this.currentBoardName === newBoardName) {
            return Promise.resolve();
        }

        const newBackgound = this.getBackground(newBoardName);
        const newBoard = this.getBoard(newBoardName);
        const newMusic = this.getBackgroundMusic(newBoardName);

        const otherBackgrounds = this.getBackgrounds().filter((m) => m[0] !== newBoardName).map((m) => m[1]);
        const otherBoards = this.getBoards().filter((m) => m[0] !== newBoardName).map((m) => m[1]);
        const otherMusics = this.getBackgroundMusics().filter((m) => m[0] !== newBoardName).map((m) => m[1]);

        const transition = (
            !!this.currentBoardName ?
                this.getTransition(this.currentBoardName, "out") :
                this.getTransition(newBoardName, "in")
        ) ?? this.getTransition(newBoardName, "in");

        this.currentBoardName = newBoardName;
        await Promise.all(this.getOverlays().map((m) => m.hide()));
        await Promise.all(this.getTransitions().map((m) => m[2].hide()));

        let transitionPromise: Promise<void> | undefined;
        if (transition) {
            transitionPromise = transition.show().then(() => transition.hide());
            await new Promise(
                (resolve) => setTimeout(
                    resolve,
                    (((transition.definition as GameTransitionDefinition).delay ?? 0) + ((transition.definition as GameTransitionDefinition).outDelay ?? 0)) * 1000
                )
            );
        }

        for (const background of otherBackgrounds) {
            gsap.to(
                background.container,
                {
                    alpha: 0,
                    duration: 0.3,
                    onComplete: () => {
                        background.container.visible = false;
                        background.container.renderable = false;
                        background.setProps({ state: "static" });
                    },
                }
            );
        }

        for (const board of otherBoards) {
            gsap.to(
                board.container,
                {
                    alpha: 0,
                    duration: 0.3,
                    onComplete: () => {
                        board.container.visible = false;
                        board.container.renderable = false;
                        board.reset();
                    },
                }
            );
        }

        for (const music of otherMusics) {
            AudioHelper.stopAudio(music);
        }

        if (transition) {
            await new Promise(
                (resolve) => setTimeout(
                    resolve,
                    ((transition.definition as GameTransitionDefinition).inDelay ?? 0) * 1000
                )
            );
        }

        if (this.shapes.controls?.container.visible === false) {
            this.shapes.controls.container.visible = true;
            this.shapes.controls.container.renderable = true;
            gsap.to(
                this.shapes.controls.container,
                {
                    alpha: 1,
                    duration: 0.3,
                }
            );
        }

        if (this.shapes.logo?.container.visible === false) {
            this.shapes.logo.container.visible = true;
            this.shapes.logo.container.renderable = true;
            gsap.to(
                this.shapes.logo.container,
                {
                    alpha: 1,
                    duration: 0.3,
                }
            );
        }

        if (newBackgound && !newBackgound.container.visible) {
            newBackgound.container.visible = true;
            newBackgound.container.renderable = true;
            newBackgound.setProps({ state: "playing" });
            gsap.to(
                newBackgound.container,
                {
                    alpha: 1,
                    duration: 0.3,
                }
            );
        }

        if (newBoard && !newBoard.container.visible) {
            newBoard.container.visible = true;
            newBoard.container.renderable = true;

            gsap.to(
                newBoard.container,
                {
                    alpha: 1,
                    duration: 0.3,
                    onComplete: () => {
                        this.resize(this.width, this.height);
                    },
                }
            );
        }

        if (newMusic) {
            AudioHelper.playAudio(newMusic);
        }

        if (!transitionPromise) {
            transitionPromise = new Promise((resolve) => setTimeout(resolve, 300));
        }

        return transitionPromise;
    }

    private getBoard(board?: TBoards): GameBoardLayer<TSymbols, TReels> | undefined {
        board = board ?? this.currentBoardName;
        if (!board) {
            return undefined;
        }

        return this.shapes[`Board_${board}` as BoardIndex<TBoards>] as GameBoardLayer<TSymbols, TReels>;
    }

    private getBoards(): Array<[TBoards, GameBoardLayer<TSymbols, TReels>]> {
        return Object
            .keys(this.game.boards)
            .map<[TBoards, GameBoardLayer<TSymbols, TReels>]>((k) => [ k as TBoards, this.shapes[`Board_${k}` as BoardIndex<TBoards>] as GameBoardLayer<TSymbols, TReels> ])
            .filter((x) => !!x[1]);
    }

    private getBackground(board?: TBoards): GameImageLayer | undefined {
        board = board ?? this.currentBoardName;
        if (!board) {
            return undefined;
        }

        return this.shapes[`Background_${board}` as BackgroundImageIndex<TBoards>] as GameImageLayer;
    }

    private getBackgrounds(): Array<[TBoards, GameImageLayer]> {
        return Object
            .keys(this.game.boards)
            .map((k) => [ k as TBoards, this.shapes[`Background_${k}` as BackgroundImageIndex<TBoards>] ])
            .filter((x) => !!x[1]) as (Array<[TBoards, GameImageLayer]>);
    }

    private getBackgroundMusic(board?: TBoards): Audio[] | undefined {
        board = board ?? this.currentBoardName;
        if (!board) {
            return undefined;
        }

        return this.musics.get(`Background_${board}` as BackgroundMusicIndex);
    }

    private getBackgroundMusics(): Array<[TBoards, Audio[]]> {
        return Object
            .keys(this.game.boards)
            .map((k) => [ k as TBoards, this.musics.get(`Background_${k}` as BackgroundMusicIndex) ])
            .filter((x) => !!x[1]) as (Array<[TBoards, Audio[]]>);
    }

    private getOverlay(modal: TOverlays): GameOverlayLayer | undefined {
        return this.shapes[`Overlay_${modal}` as OverlayIndex<TOverlays>] as GameOverlayLayer;
    }

    private getOverlays(): GameOverlayLayer[] {
        return Object
            .keys(this.game.overlays)
            .map<GameOverlayLayer>((k) => this.shapes[`Overlay_${k}` as OverlayIndex<TOverlays>] as GameOverlayLayer)
            .filter((x) => !!x);
    }

    private getTransition(board: TBoards, direction: "in" | "out"): GameOverlayLayer | undefined {
        return this.shapes[`Transition_${board}_${(direction === "in" ? "In" : "Out")}` as TransitionIndex<TBoards>] as GameOverlayLayer;
    }

    private getTransitions(): Array<[TBoards, "in" | "out", GameOverlayLayer]> {
        return Object
            .keys(this.game.boards)
            .map<[TBoards, "in" | "out", GameOverlayLayer]>((k) => [ k as TBoards, "in", this.shapes[`Transition_${k}_In` as TransitionIndex<TBoards>] as GameOverlayLayer ])
            .concat(
                Object
                    .keys(this.game.boards)
                    .map<[TBoards, "in" | "out", GameOverlayLayer]>((k) => [ k as TBoards, "out", this.shapes[`Transition_${k}_Out` as TransitionIndex<TBoards>] as GameOverlayLayer ])
            )
            .filter((x) => !!x[2]);
    }
}
