import * as React from "react";
import { AppProperties } from "./app.properties";
import { DefuseGamePage } from "./components/defuse-game-page/defuse-game-page.component";
import { GameConfiguration, GameTypes } from "@tgg/shared/src";

const CurrencyShortForm: { [index: string]: string } = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
};

export default class App extends React.PureComponent<AppProperties, {
    userId?: number;
    screenHeight?: number;
    balance?: number;
    currency?: string;
    config?: GameConfiguration;
    marbleId?: string;
    language: string;
}> {
    public constructor(props: AppProperties) {
        super(props);
        let marbleId: string | undefined;
        let language = "en";
        if (typeof window === "object") {
            const url = new URL(window.location.href);
            marbleId = url.searchParams.get("playerToken") || undefined;
            language = url.searchParams.get("lang")?.toLowerCase().substring(0, 2) || language;

            if (
                !(
                    [
                        "en",
                        "ru",
                        "es",
                    ].includes(language)
                )
            ) {
                language = "en";
            }
        }

        this.state = {
            marbleId,
            language,
        };
    }

    public componentDidMount(): void {
        if (this.state.marbleId) {
            this.props.sessionService.getOrCreateSession(this.state.marbleId, GameTypes.Slots).then(
                (s) => {
                    this.props.financeService.onBalanceUpdated.sub(
                        (b) => this.setState(
                            {
                                balance: b.amount,
                                currency: b.currency && typeof CurrencyShortForm[b.currency] !== "undefined" ? CurrencyShortForm[b.currency] : b.currency,
                            }
                        )
                    );
                    this.props.slotsService.setToken(s.token);
                    this.props.financeService.setToken(s.token);
                    void this.props.financeService.connectWebSocket();
                    this.setState(
                        {
                            userId: s.userId,
                            config: s.config ?? undefined,
                        }
                    );
                },
                (e: string | Error) => alert(`Failed to authenticate: ${e.toString()}`)
            );
        }

        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentWillUnmount(): void {
        this.props.financeService.onBalanceUpdated.clear();
        window.removeEventListener("resize", this.onResize);
    }

    public render(): React.ReactNode {
        return <DefuseGamePage
            userId={this.state.userId}
            slotsService={this.props.slotsService}
            balance={this.state.balance}
            currency={this.state.currency}
            config={this.state.config}
            isDemo={!this.state.marbleId}
            style={{ ["--screen-height" as any]: this.state.screenHeight ? `${this.state.screenHeight}px` : "100vh" }}
            language={this.state.language}
        />;
    }

    private onResize = () => {
        this.setState(
            {
                screenHeight: window.innerHeight,
            }
        );
    };
}
