import * as PIXI from "pixi.js";
import {
    GameBindingsMap,
    GameButtonDefinition,
    GameFormDefinition,
    GameFormElementType,
    GameImageDefinition,
    GameRectangleDefinition,
    GameTabDefinition,
    GameTextDefinition
} from "../../game.properties";
import { GameButtonLayer } from "../button/game-button.layer";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameFormLayerProps } from "./game-form-layer.props";
import { GameImageLayer } from "../image/game-image.layer";
import { GameRectLayer } from "../rect/game-rect.layer";
import { GameTabLayer } from "../tab/game-tab.layer";
import { GameTextLayer } from "../text/game-text.layer";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import gsap from "gsap";

export class GameFormLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    [key: string]: GameButtonLayer | GameImageLayer | GameTextLayer | GameRectLayer | GameTabLayer;
},
"",
GameFormLayerProps,
PIXI.Container
> {
    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameFormDefinition,
        bindings: GameBindingsMap = {},
        additionalActions: {
            [key: string]: (() => (Promise<void> | void));
        } = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "disabled",
            },
            {
                ...(
                    Object.fromEntries(
                        Object.keys(definition.elements)
                            .map(
                                (key) => {
                                    switch (definition.elements[key].type) {
                                        case GameFormElementType.Button:
                                            return [
                                                key,
                                                new GameButtonLayer(
                                                    configuration,
                                                    app,
                                                    definition.elements[key].payload as GameButtonDefinition,
                                                    additionalActions
                                                ),
                                            ];
                                        case GameFormElementType.Image:
                                            return [
                                                key,
                                                new GameImageLayer(
                                                    configuration,
                                                    app,
                                                    definition.elements[key].payload as GameImageDefinition
                                                ),
                                            ];
                                        case GameFormElementType.Text:
                                            return [
                                                key,
                                                new GameTextLayer(
                                                    configuration,
                                                    app,
                                                    definition.elements[key].payload as GameTextDefinition,
                                                    bindings
                                                ),
                                            ];
                                        case GameFormElementType.Rectangle:
                                            return [
                                                key,
                                                new GameRectLayer(
                                                    configuration,
                                                    app,
                                                    definition.elements[key].payload as GameRectangleDefinition
                                                ),
                                            ];
                                        case GameFormElementType.Tab:
                                            return [
                                                key,
                                                new GameTabLayer(
                                                    configuration,
                                                    app,
                                                    definition.elements[key].payload as GameTabDefinition,
                                                    bindings,
                                                    additionalActions
                                                ),
                                            ];
                                        default:
                                            throw new Error("Unknown form element type provided.");
                                    }
                                }
                            )
                    )
                ),
            }
        );

        // for (const key in this.definition.elements) {
        //     if (!Object.prototype.hasOwnProperty.call(this.definition.elements, key)) {
        //         continue;
        //     }

        //     const element = this.definition.elements[key];
        //     const shape = this.shapes[key];
        //     shape.container.alpha = element.payload.alpha ?? 1;
        // }
    }

    public destroy(): void {
        super.destroy();
    }

    public resize(width: number, height: number): void {
        const box = this.calculateLayout(width, height, this.definition);
        super.resize(box.width, box.height);
        super.move(box.x, box.y);

        for (const key in this.definition.elements) {
            if (!Object.prototype.hasOwnProperty.call(this.definition.elements, key)) {
                continue;
            }

            const element = this.definition.elements[key];
            const shape = this.shapes[key];

            if (shape instanceof GameButtonLayer) {
                shape.resize(
                    ((element.payload.width ?? 0) * box.scale) || box.width,
                    ((element.payload.height ?? 0) * box.scale) || box.height
                );

                shape.move(box.width / 2, box.height / 2);
            }

            if (shape instanceof GameTextLayer) {
                shape.resize(
                    ((element.payload.width ?? 0) * box.scale) || box.width,
                    ((element.payload.height ?? 0) * box.scale) || box.height
                );

                shape.move(0, 0);
            }

            if (shape instanceof GameImageLayer) {
                shape.resize(
                    ((element.payload.width ?? 0) * box.scale) || box.width,
                    ((element.payload.height ?? 0) * box.scale) || box.height
                );

                shape.move(0, 0);
            }

            if (shape instanceof GameRectLayer) {
                shape.resize(
                    ((element.payload.width ?? 0) * box.scale) || box.width,
                    ((element.payload.height ?? 0) * box.scale) || box.height
                );

                shape.move(0, 0);
            }

            if (shape instanceof GameTabLayer) {
                shape.resize(
                    ((element.payload.width ?? 0) * box.scale) || box.width,
                    ((element.payload.height ?? 0) * box.scale) || box.height
                );

                shape.move(0, 0);
            }
        }
    }

    public updateBindings(bindings: GameBindingsMap): void {
        for (const key in this.definition.elements) {
            if (!Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                continue;
            }
            const shape = this.shapes[key];

            if (shape instanceof GameTextLayer) {
                shape.setProps(
                    {
                        bindings: {
                            ...shape.getProps().bindings,
                            ...bindings,
                        },
                    }
                );
            }
        }
    }

    protected stateChanged(props: GameFormLayerProps): void {
        if (props.state === "disabled") {
            for (const key in this.definition.elements) {
                if (!Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                    continue;
                }

                const shape = this.shapes[key];
                if (shape instanceof GameButtonLayer) {
                    shape.setProps({ state: "disabled" });
                }

                if (shape instanceof GameImageLayer) {
                    shape.setProps({ state: "static" });
                }
            }
        } else if (props.state === "enabled") {
            for (const key in this.definition.elements) {
                if (!Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                    continue;
                }

                const shape = this.shapes[key];
                const animatedDelay = this.definition.elements[key].appearDelay;
                const animationDuration = this.definition.elements[key].appearDuration;
                if (!!animatedDelay || !!animationDuration) {
                    shape.container.alpha = 0;
                    setTimeout(
                        () => {
                            this.container.visible = true;
                            this.container.renderable = true;
                            if (shape instanceof GameImageLayer) {
                                shape.setProps({ state: "playing" });
                            } else if (shape instanceof GameButtonLayer) {
                                shape.setProps({ state: "normal" });
                            } else if (shape instanceof GameTextLayer) {
                                shape.setProps(
                                    {
                                        bindings: {
                                            ...shape.getProps().bindings,
                                        },
                                    }
                                );
                            }

                            if (animationDuration !== 0) {
                                gsap.fromTo(
                                    shape.container,
                                    {
                                        alpha: 0,
                                    },
                                    {
                                        alpha: 1,
                                        duration: animationDuration ?? 0.3,
                                    }
                                );
                            } else {
                                shape.container.alpha = 1;
                            }
                        },
                        (animatedDelay ?? 0) * 1000
                    );
                } else {
                    this.container.visible = true;
                    this.container.renderable = true;
                    shape.container.alpha = 1;
                    if (shape instanceof GameImageLayer) {
                        shape.setProps({ state: "playing" });
                    } else if (shape instanceof GameButtonLayer) {
                        shape.setProps({ state: "normal" });
                    }
                }
            }
        } else if (props.state === "busy") {
            for (const key in this.definition.elements) {
                if (!Object.prototype.hasOwnProperty.call(this.shapes, key)) {
                    continue;
                }

                const shape = this.shapes[key];
                if (shape instanceof GameButtonLayer) {
                    shape.setProps({ state: "busy" });
                }
            }
        }
    }

    protected loaded(): void {
        super.loaded();
        this.resize(this.width, this.height);
    }
}
