import * as PIXI from "pixi.js";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameImageDefinition } from "../../game.properties";
import { GameImageLayerProps } from "./game-image-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";

export class GameImageLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    wrapper: PIXI.Container;
},
string,
GameImageLayerProps,
PIXI.Container
> {
    private sprite: PIXI.AnimatedSprite | PIXI.Sprite | undefined;
    private debugGraphic2?: PIXI.Graphics;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameImageDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: definition.autoplay ? "playing" : "static",
                startIndex: 0,
            },
            {
                wrapper: new PIXI.Container(),
            },
            {
                [`Image_${definition.url}`]: definition.url,
            }
        );

        this.shapes.wrapper.alpha = definition.alpha ?? 1;
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        if (this.sprite) {
            this.sprite.scale.x = 1;
            this.sprite.scale.y = 1;
            this.sprite.x = 0;
            this.sprite.y = 0;

            const box = this.calculateLayout(
                width,
                height,
                {
                    ...this.definition,
                    width: this.definition.width || this.sprite.texture.width,
                    height: this.definition.height || this.sprite.texture.height,
                }
            );

            this.shapes.wrapper.scale.x = box.scale;
            this.shapes.wrapper.scale.y = box.scale;
            this.shapes.wrapper.x = box.left;
            this.shapes.wrapper.y = box.top;
        }

        if (this.debugGraphic2) {
            this.debug();
        }
    }

    public debug(): void {
        super.debug();
        if (!this.debugGraphic2) {
            this.debugGraphic2 = new PIXI.Graphics();
            this.container.addChildAt(this.debugGraphic2, 0);
        }

        this.debugGraphic2.clear();
        this.debugGraphic2.beginFill(0x0000ff);
        this.debugGraphic2.drawRect(this.shapes.wrapper.x, this.shapes.wrapper.y, this.shapes.wrapper.width, this.shapes.wrapper.height);
        this.debugGraphic2.endFill();
    }

    protected stateChanged(): void {
        const asset = this.assets[`Image_${this.definition.url}`];
        if (asset.videoControl) {
            if (this.props.state === "playing") {
                void asset.videoControl.play().catch(() => undefined);
            } else {
                asset.videoControl.pause();
                asset.videoControl.currentTime = 0;
            }
        } else if (this.sprite instanceof PIXI.AnimatedSprite) {
            const index = this.props.startIndex ?? Math.floor(Math.random() * (asset.textures?.length ?? 0));
            if (this.props.state === "playing") {
                this.sprite.gotoAndPlay(index);
            } else {
                this.sprite.gotoAndStop(index);
            }
        }
    }

    protected loaded(): void {
        super.loaded();

        const asset = this.assets[`Image_${this.definition.url}`];
        if (asset.videoControl) {
            document.addEventListener(
                "click",
                () => {
                    this.update();
                },
                { once: true }
            );
        } else {
            this.update();
        }
    }

    private update() {
        const asset = this.assets[`Image_${this.definition.url}`];
        if (!this.sprite && asset?.textures?.length) {
            if (asset?.textures.length > 1) {
                this.sprite = new PIXI.AnimatedSprite(asset.textures);
                (this.sprite as PIXI.AnimatedSprite).animationSpeed = 0.5;
                (this.sprite as PIXI.AnimatedSprite).loop = this.definition.loop ?? true;
            } else if (!!asset.textures[0].baseTexture) {
                this.sprite = new PIXI.Sprite(asset.textures[0]);
            }

            if (this.sprite) {
                this.sprite.blendMode = this.definition.blend ?? PIXI.BLEND_MODES.NORMAL;
                this.shapes.wrapper.addChild(this.sprite);
            }
        }

        this.resize(
            this.width || this.definition.width || 0,
            this.height || this.definition.height || 0
        );

        this.stateChanged();
    }
}
