import * as PIXI from "pixi.js";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameRectangleDefinition } from "../../game.properties";
import { GameRectLayerProps } from "./game-rect-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";

export class GameRectLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    background: PIXI.Graphics;
},
string,
GameRectLayerProps,
PIXI.Container
> {
    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameRectangleDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "static",
            },
            {
                background: new PIXI.Graphics(),
            }
        );

        this.shapes.background.alpha = definition.alpha ?? 1;
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);

        this.shapes.background.scale.x = 1;
        this.shapes.background.scale.y = 1;
        this.shapes.background.x = 0;
        this.shapes.background.y = 0;

        const box = this.calculateLayout(
            width,
            height,
            this.definition
        );

        this.shapes.background.clear();
        if (this.definition.background != null) {
            this.shapes.background.beginFill(this.definition.background);
        }

        if (box.x > 0 || box.y > 0) {
            this.shapes.background.drawRoundedRect(
                box.x,
                box.y,
                box.width,
                box.height,
                this.definition.cornerRadius ?? 0
            );
        } else {
            this.shapes.background.drawRect(box.x, box.y, box.width, box.height);
        }

        this.shapes.background.endFill();
    }
}
