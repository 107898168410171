import * as PIXI from "pixi.js";
import { GameBindingsMap, GameTabDefinition } from "../../game.properties";
import { GameButtonLayer } from "../button/game-button.layer";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameFormLayer } from "../form/game-form.layer";
import { GameTabLayerProps } from "./game-tab-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import gsap from "gsap";

export class GameTabLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    [index: string]: GameFormLayer | GameButtonLayer;
},
string,
GameTabLayerProps,
PIXI.Container
> {
    private currentTab: string;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameTabDefinition,
        bindings: GameBindingsMap = {},
        additionalActions: {
            [key: string]: (() => (Promise<void> | void));
        } = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "disabled",
            },
            {
                ...(
                    Object.fromEntries(
                        Object.keys(definition.tabs)
                            .map(
                                (key) => (
                                    [
                                        `tab_${key}`,
                                        new GameFormLayer(
                                            configuration,
                                            app,
                                            definition.tabs[key],
                                            bindings,
                                            additionalActions
                                        ),
                                    ]
                                )
                            )
                    )
                ),
                ...(
                    Object.fromEntries(
                        Object.keys(definition.tabs)
                            .map(
                                (key) => (
                                    [
                                        `button_${key}`,
                                        new GameButtonLayer(
                                            configuration,
                                            app,
                                            {
                                                ...definition.button,
                                                action: () => this.onSwitchTab(key),
                                            },
                                            additionalActions
                                        ),
                                    ]
                                )
                            )
                    )
                ),
            }
        );

        this.currentTab = Object.keys(definition.tabs)[0];
    }

    public resize(width: number, height: number): void {
        const box = this.calculateLayout(width, height, this.definition);
        super.resize(box.width, box.height);
        super.move(box.x, box.y);

        const buttonWidth = ((this.definition.button.width ?? 0) * box.scale) || box.width;
        const buttonHeight = ((this.definition.button.height ?? 0) * box.scale) || box.height;
        const totalWidth = buttonWidth * Object.keys(this.definition.tabs).length;
        let startingX = (box.width / 2) - (totalWidth / 2) + (buttonWidth / 2);
        const startingY = (box.height / 2) + (buttonHeight / 2);
        for (const tab in this.definition.tabs) {
            if (!Object.prototype.hasOwnProperty.call(this.definition.tabs, tab)) {
                continue;
            }

            this.shapes[`tab_${tab}`].resize(width, height);
            this.shapes[`button_${tab}`].resize(buttonWidth, buttonHeight);
            this.shapes[`button_${tab}`].move(startingX, startingY);

            startingX += ((this.definition.button.width ?? 0) * box.scale) || box.width;
        }
    }

    protected loaded(): void {
        super.loaded();
        this.resize(this.width, this.height);
        this.changeTab();
    }

    protected stateChanged(props: GameTabLayerProps): void {
        for (const tab in this.definition.tabs) {
            if (!Object.prototype.hasOwnProperty.call(this.definition.tabs, tab)) {
                continue;
            }

            const button = (this.shapes[`button_${tab}`] as GameButtonLayer);
            const form = (this.shapes[`tab_${tab}`] as GameFormLayer);

            form.setProps(props);
            button.setProps({ state: this.currentTab === tab ? "disabled" : "normal" });
        }
    }

    private onSwitchTab = (selectedTab: string) => {
        if (selectedTab === this.currentTab) {
            return;
        }
        this.currentTab = selectedTab;
        this.changeTab();
    };

    private changeTab() {
        for (const tab in this.definition.tabs) {
            if (!Object.prototype.hasOwnProperty.call(this.definition.tabs, tab)) {
                continue;
            }

            const button = (this.shapes[`button_${tab}`] as GameButtonLayer);
            const form = (this.shapes[`tab_${tab}`] as GameFormLayer);
            if (tab === this.currentTab) {
                form.container.visible = true;
                form.container.renderable = true;

                gsap.to(
                    form.container,
                    {
                        alpha: 1,
                        duration: 0.3,
                        onComplete: () => {
                            this.resize(this.width, this.height);
                        },
                    }
                );
                button.setProps({ state: "disabled" });
            } else {
                gsap.to(
                    form.container,
                    {
                        alpha: 0,
                        duration: 0.3,
                        onComplete: () => {
                            form.container.visible = false;
                            form.container.renderable = false;
                        },
                    }
                );
                button.setProps({ state: "normal" });
            }
        }
    }
}
