import * as React from "react";
import { Button } from "../button/button.component";
import { ChevronDown, ChevronUp } from "react-feather";
import { MobileControlsProperties } from "./mobile-controls.properties";
import { Translate } from "../translate/translate";
import styles from "./mobile-controls.module.scss";

export class MobileControls extends React.PureComponent<MobileControlsProperties> {
    public constructor(props: MobileControlsProperties) {
        super(props);
        this.state = { };
    }

    public render(): React.ReactNode {
        return <aside className={styles.controls}>
            <div>
                <section className={styles.options}>
                    <Button
                        onClick={this.props.actions["toggle-fast-mode"]}
                        isActive={this.props.isFastModeActive}
                    ><Translate>QUICK</Translate></Button>
                    <Button
                        onClick={this.props.actions["toggle-auto-play"]}
                        isActive={!!this.props.autoPlayRounds}
                    ><Translate>AUTO</Translate></Button>
                </section>
            </div>
            <div>
                <section className={styles.amount}>
                    <span>
                        <h5><Translate>BET</Translate></h5>
                        <span>{this.props.currency}{this.props.totalBetAmount.toFixed(2)}</span>
                    </span>
                </section>
                <section className={styles.spin}>
                    <Button
                        onClick={this.props.actions.spin}
                        isDisabled={this.props.isSkipping}
                    >
                        {
                            !!this.props.isFastModeActive && <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    // eslint-disable-next-line max-len
                                    d="M18.663 3.9619C16.001 2.25058 12.5 4.16195 12.5 7.32661V64.6733C12.5 67.838 16.001 69.7493 18.663 68.038L63.266 39.3647C65.7153 37.7901 65.7153 34.2098 63.266 32.6352L18.663 3.9619ZM34.2375 24.9677C34.6956 25.1666 34.9678 25.6437 34.9059 26.1392L34.0639 32.8747H41.7896C42.2261 32.8747 42.6232 33.1272 42.8083 33.5225C42.9935 33.9178 42.9333 34.3846 42.6538 34.7199L32.6538 46.7199C32.3341 47.1036 31.7997 47.2305 31.3416 47.0317C30.8835 46.8328 30.6113 46.3557 30.6733 45.8602L31.5152 39.1247H23.7896C23.353 39.1247 22.9559 38.8722 22.7708 38.4769C22.5856 38.0816 22.6459 37.6148 22.9253 37.2795L32.9253 25.2795C33.245 24.8958 33.7794 24.7689 34.2375 24.9677Z"
                                    fill="white" />
                            </svg>

                        }
                        {
                            !this.props.isFastModeActive && <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    // eslint-disable-next-line max-len
                                    d="M12.5 7.32666C12.5 4.162 16.001 2.25063 18.663 3.96195L63.266 32.6353C65.7153 34.2098 65.7153 37.7902 63.266 39.3647L18.663 68.0381C16.001 69.7494 12.5 67.838 12.5 64.6733V7.32666Z"
                                    fill="white"
                                />
                            </svg>
                        }
                        {!!this.props.autoPlayRounds && <span>{this.props.autoPlayRounds.toFixed(0)}</span>}
                    </Button>
                </section>
                <section className={styles.bet}>
                    <Button
                        onClick={this.props.actions["max-bet"]}
                        isDisabled={this.props.isBusy}
                        sound="coins-max"
                    ><Translate>MAX BET</Translate></Button>
                    <section>
                        <Button
                            onClick={this.props.actions["decrease-bet"]}
                            isDisabled={this.props.isBusy}
                            sound="coins"
                        ><ChevronDown /></Button>
                        <Button
                            onClick={this.props.actions["increase-bet"]}
                            isDisabled={this.props.isBusy}
                            sound="coins"
                        ><ChevronUp /></Button>
                    </section>
                </section>
            </div>
        </aside>;
    }
}
