import * as PIXI from "pixi.js";
import { PixiJSLayer } from "./pixijs-layer";
import { PixiJSRenderingLayer } from "./pixijs-rendering-layer";

export class PixiJSLayerPool<TConfig, T extends PIXI.Container | PixiJSRenderingLayer<TConfig>> implements PixiJSLayer<TConfig> {
    public readonly container: PIXI.Container;
    protected width = 0;
    protected height = 0;
    protected x = 0;
    protected y = 0;
    private pool: T[] = [];

    public constructor(
        private readonly creator: () => T,
        private readonly stateManager?: (obj: T, wakeup: boolean) => void
    ) {
        this.container = new PIXI.Container();
        this.get(1);
    }

    public destroy(): void {
        this.container.destroy({ children: true, texture: false, baseTexture: false });
    }

    public getPool(): T[] {
        return [ ...this.pool ];
    }

    public clearPool(): void {
        for (const obj of this.pool) {
            if (this.stateManager) {
                this.stateManager(obj, false);
            }

            if (typeof (obj as PixiJSRenderingLayer).container !== "undefined") {
                this.container.removeChild((obj as PixiJSRenderingLayer).container);
            } else {
                this.container.removeChild(obj as PIXI.Container);
            }

            // obj.destroy({ children: true, texture: false, baseTexture: false });
        }

        this.pool = [];
    }

    public resize(width: number, height: number): void {
        if (this.width !== width) {
            // this.container.width = width;
            this.width = width;
        }

        if (this.height !== height) {
            // this.container.height = height;
            this.height = height;
        }
    }

    public move(x: number, y: number): void {
        if (this.x !== x) {
            this.container.x = x;
            this.x = x;
        }

        if (this.y !== y) {
            this.container.y = y;
            this.y = y;
        }
    }

    public get(count: number): T[] {
        const objects = this.pool.filter((_, i) => i < count);
        const reserve = this.pool.filter((_, i) => i >= count);

        if (this.stateManager) {
            for (const obj of objects) {
                this.stateManager(obj, true);
            }

            for (const obj of reserve) {
                this.stateManager(obj, false);
            }
        }

        while (objects.length < count) {
            const obj = this.creator();
            objects.push(obj);
            this.pool.push(obj);
        }

        return objects;
    }

    public config(configuration: TConfig): void {
        for (const shape of this.getPool()) {
            if (!shape) {
                continue;
            }

            if (typeof (shape as PixiJSRenderingLayer).config !== "undefined") {
                (shape as PixiJSRenderingLayer).config(configuration);
            }
        }
    }

    public transaction(callback: (getOne: () => T) => void): void {
        const reserve = [ ...(this.pool) ];

        callback(
            () => {
                let obj = reserve.shift();
                if (typeof obj === "undefined") {
                    obj = this.creator();
                    this.pool.push(obj);
                } else if (this.stateManager) {
                    this.stateManager(obj, true);
                }

                if (typeof (obj as PixiJSRenderingLayer).container !== "undefined") {
                    this.container.addChild((obj as PixiJSRenderingLayer).container);
                } else {
                    this.container.addChild(obj as PIXI.Container);
                }
                return obj;
            }
        );

        for (const obj of reserve) {
            if (this.stateManager) {
                this.stateManager(obj, false);
            }

            if (typeof (obj as PixiJSRenderingLayer).container !== "undefined") {
                this.container.removeChild((obj as PixiJSRenderingLayer).container);
            } else {
                this.container.removeChild(obj as PIXI.Container);
            }
        }
    }
}
