/* eslint-disable max-classes-per-file */
export interface PreloadAsset {
    url: string;
    local?: string;
    status: "registered" | "loading" | "loaded" | "failed";
    callback?: (asset: PreloadAsset) => void;
}

export class PreloadProgressEvent extends Event {
    public readonly completed: boolean;
    public readonly progress: number;

    public constructor(name: string, total: number, completed: number) {
        super(name);

        this.completed = total > 0 && total === completed;
        this.progress = total === 0 ? 0 : (completed / total) * 100;
    }
}

export class PreloadHelper {
    public static assets = new Map<string, PreloadAsset>();
    private static completed = 0;

    public static preload(url: string, callback?: (asset: PreloadAsset) => void): PreloadAsset {
        let asset = PreloadHelper.assets.get(url);
        if (!asset) {
            asset = {
                url,
                status: "registered",
            };

            PreloadHelper.assets.set(url, asset);
        }

        if (callback) {
            const oldCallback = asset.callback;
            asset.callback = (a) => {
                if (oldCallback) {
                    oldCallback(a);
                }

                callback(a);
            };
        }

        if (!!PreloadHelper.completed) {
            void this.load();
        }

        return asset;
    }

    public static get(url: string): PreloadAsset | undefined {
        return PreloadHelper.assets.get(url);
    }

    public static async load(): Promise<void> {
        const assets: PreloadAsset[] = [];
        for (const asset of PreloadHelper.assets.values()) {
            if (asset.status !== "registered") {
                continue;
            }

            asset.status = "loading";
            assets.push(asset);
        }

        for (const asset of assets) {
            try {
                await new Promise<void>(
                    (resolve) => {
                        const xhr = new XMLHttpRequest();
                        xhr.open("GET", asset.url, true);
                        xhr.responseType = "blob";

                        xhr.onload = function() {
                            if (this.status !== 200) {
                                PreloadHelper.failed(asset);
                            } else {
                                PreloadHelper.loaded(asset, this.response);
                            }

                            resolve();
                        };

                        xhr.onerror = () => {
                            PreloadHelper.failed(asset);
                            resolve();
                        };

                        xhr.send();
                    }
                );
            } catch (error) {
                PreloadHelper.failed(asset);
            }
        }
    }

    public static addEventListener(c: (event: PreloadProgressEvent) => void, options?: boolean | AddEventListenerOptions): void {
        if (typeof document === "undefined") {
            return;
        }

        document.addEventListener("preload-progress" as any, c, options);
    }

    private static failed = (asset: PreloadAsset) => {
        PreloadHelper.completed++;
        asset.status = "failed";
        PreloadHelper.notify();

        if (asset.callback) {
            try {
                asset.callback(asset);
            } catch (error) {
                // ignore
            }
        }
    };

    private static loaded = (asset: PreloadAsset, response: Blob) => {
        try {
            asset.local = URL.createObjectURL(response);
            asset.status = "loaded";
            PreloadHelper.completed++;
            PreloadHelper.notify();

            if (asset.callback) {
                try {
                    asset.callback(asset);
                } catch (error) {
                    // ignore
                }
            }
        } catch (error) {
            PreloadHelper.failed(asset);
        }
    };

    private static notify() {
        if (typeof document === "undefined") {
            return;
        }

        try {
            document.dispatchEvent(
                new PreloadProgressEvent(
                    "preload-progress",
                    [ ...this.assets.values() ].length,
                    PreloadHelper.completed
                )
            );
        } catch (error) {
            // ignore
        }
    }
}
