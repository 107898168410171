import { LanguageContextConsumer } from "./language.context";
import languages from "../../languages.json";
import React from "react";

export class Translate extends React.PureComponent<{children: string}> {
    public render(): React.ReactNode {
        let text = this.props.children;
        return <LanguageContextConsumer>
            {
                (language) => {
                    const langs = languages as Record<string, Record<string, string | undefined> | undefined>;
                    if (!!language.language && typeof langs[language.language] === "object") {
                        text = langs[language.language]![text] || text;
                    }

                    return text;
                }
            }
        </LanguageContextConsumer>;
    }
}
