import * as React from "react";
import { Button } from "../button/button.component";
import { ChevronLeft, ChevronRight, XCircle } from "react-feather";
import { GuideModalProperties } from "./guide-modal.properties";

import { Checkbox } from "../checkbox/checkbox.component";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { PreloadHelper } from "../../common/helpers/preload.helper";
import { Translate } from "../translate/translate";
import classNames from "classnames";
import styles from "./guide-modal.module.scss";

export class GuideModal extends React.PureComponent<GuideModalProperties, {
    currentTab: number;
}> {
    private isComponentMounted = false;

    public constructor(props: GuideModalProperties) {
        super(props);
        this.state = {
            currentTab: 0,
        };

        for (const slide of props.slides) {
            PreloadHelper.preload(slide.image);
        }
    }

    public componentDidMount(): void {
        this.isComponentMounted = true;
        PreloadHelper.addEventListener(
            (p) => {
                if (!this.isComponentMounted || !p.completed) {
                    return;
                }
                this.forceUpdate();
            }
        );
    }

    public componentWillUnmount(): void {
        this.isComponentMounted = false;
    }

    public render(): React.ReactNode {
        return <article
            className={
                classNames(
                    styles.modal,
                    {
                        [styles.mobile]: this.props.isMobile,
                    }
                )
            }
        >
            {this.props.isMobile ? this.renderMobile() : this.renderDesktop()}
        </article>;
    }

    private renderDesktop(): React.ReactNode {
        const currentTab = this.props.slides[this.state.currentTab];

        return <>
            <header>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        classNames="fade"
                        timeout={300}
                        key={currentTab.title}
                    >
                        <h1>{currentTab.title}</h1>
                    </CSSTransition>
                </SwitchTransition>
                <Button
                    onClick={this.onClose}
                    style="game"
                >
                    {!this.props.isMobile && <Translate>CLOSE</Translate>}
                    {!!this.props.isMobile && <XCircle />}
                </Button>
            </header>
            <main>
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        classNames="fade"
                        timeout={300}
                        key={this.state.currentTab}
                    >
                        <section>
                            <img src={PreloadHelper.get(currentTab.image)?.local} alt={currentTab.title} />
                        </section>
                    </CSSTransition>
                </SwitchTransition>
            </main>
            <footer>
                <Button
                    isDisabled={this.state.currentTab <= 0}
                    onClick={() => this.setTab(this.state.currentTab - 1)}
                    style="game"
                ><ChevronLeft /></Button>
                <div>
                    {
                        this.props.slides.map(
                            (_, i) => <Checkbox
                                key={i}
                                isActive={i === this.state.currentTab}
                                onToggle={() => this.setTab(i)}
                                style="game"
                            />
                        )
                    }
                </div>
                <Button
                    isDisabled={this.state.currentTab >= (this.props.slides.length - 1)}
                    onClick={() => this.setTab(this.state.currentTab + 1)}
                    style="game"
                ><ChevronRight /></Button>
            </footer>
        </>;
    }

    private renderMobile(): React.ReactNode {
        return <>
            <header>
                <Button
                    onClick={this.onClose}
                    style="game"
                >
                    {!this.props.isMobile && <Translate>CLOSE</Translate>}
                    {!!this.props.isMobile && <XCircle />}
                </Button>
            </header>
            <main>
                <div>
                    {
                        this.props.slides.map(
                            (t, i) => <section
                                key={i} className={i === this.state.currentTab ? styles.active : undefined}
                                onClick={() => this.setTab(i)}
                            >
                                <h1>{t.title}</h1>
                                <img src={PreloadHelper.get(t.image)?.local} alt={t.title} />
                            </section>
                        )
                    }
                </div>
            </main>
        </>;
    }

    private setTab(index: number) {
        this.setState(
            {
                currentTab: index,
            }
        );
    }

    private onClose = () => {
        if (!this.props.onExit) {
            return;
        }

        this.props.onExit();
    };
}
