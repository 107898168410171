import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import { GameBindingsMap, GameOverlayDefinition } from "../../game.properties";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameFormLayer } from "../form/game-form.layer";
import { GameOverlayLayerProps } from "./game-overlay-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import gsap from "gsap";

const TransitionDuration = 0.5;
export class GameOverlayLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    form: GameFormLayer;
},
string,
GameOverlayLayerProps,
PIXI.Container
> {
    private sound: Audio[] = [];
    private timer: ReturnType<typeof setTimeout> | undefined;
    private currentResolve: (() => void) | undefined;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        public readonly definition: GameOverlayDefinition,
        bindings: GameBindingsMap = {},
        additionalActions: {
            [key: string]: (() => (Promise<void> | void));
        } = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "hidden",
            },
            {
                form: new GameFormLayer(
                    configuration,
                    app,
                    definition,
                    bindings,
                    additionalActions
                ),
            }
        );

        if (definition.canSkip) {
            this.container.interactive = true;
            this.container.on("pointertap", this.onClick);
        }

        this.container.alpha = 0;
        this.container.visible = false;
        this.container.renderable = false;
        this.sound = AudioHelper.getAudioList(definition.audio);
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        this.shapes.form.resize(width, height);
    }

    public show(bindings?: GameBindingsMap): Promise<void> {
        if (bindings) {
            this.shapes.form.updateBindings(bindings);
        }

        return new Promise(
            (resolve) => {
                if (this.currentResolve) {
                    this.currentResolve();
                }

                if (this.definition.blockEnter === false) {
                    this.currentResolve = undefined;
                    resolve();
                } else {
                    this.currentResolve = resolve;
                }

                if (this.timer) {
                    clearTimeout(this.timer);
                }

                this.timer = setTimeout(
                    () => {
                        this.timer = undefined;
                        this.setProps({ state: "visible" });

                        const implicitDuration = Object.values(this.definition.elements).reduce<number>((a, b) => Math.max(a, b.appearDelay ?? 0), 0);
                        let duration = this.definition.duration;
                        if (
                            (!duration && !!this.configuration.autoPlayRounds) ||
                            this.configuration.isFastModeActive
                        ) {
                            duration = this.definition.fastDuration ? this.definition.fastDuration : (0.7 + implicitDuration);
                        }

                        if (duration != null) {
                            this.timer = setTimeout(
                                () => {
                                    this.timer = undefined;
                                    void this.hide();
                                },
                                (duration + TransitionDuration) * 1000
                            );
                        } else if (!this.definition.canSkip && this.definition.blockEnter !== false) {
                            setTimeout(
                                resolve,
                                implicitDuration + TransitionDuration
                            );
                        }
                    },
                    (this.definition.delay ?? 0) * 1000
                );
            }
        );
    }

    public hide(): Promise<void> {
        if (this.props.state !== "visible") {
            return Promise.resolve();
        }

        return new Promise(
            (resolve) => {
                if (this.timer) {
                    clearTimeout(this.timer);
                }

                let delay = 0;
                if (
                    this.definition.blockExit === false &&
                    (this.configuration.isFastModeActive || !!this.configuration.autoPlayRounds)
                ) {
                    delay = 1;
                } else if (
                    this.definition.blockExit === false || this.configuration.isFastModeActive
                ) {
                    resolve();
                }

                this.timer = setTimeout(
                    () => {
                        this.setProps({ state: "hidden" });

                        this.timer = undefined;
                        if (this.currentResolve) {
                            this.currentResolve();
                            this.currentResolve = undefined;
                        }

                        resolve();
                    },
                    delay * 1000
                );
            }
        );
    }

    protected loaded(): void {
        super.loaded();
        this.resize(this.width, this.height);
        this.container.visible = false;
        this.container.renderable = false;
    }

    protected stateChanged(props: GameOverlayLayerProps): void {
        if (props.state === "hidden") {
            gsap.to(
                this.container,
                {
                    alpha: 0,
                    duration: TransitionDuration,
                    onComplete: () => {
                        this.shapes.form.setProps({ state: "disabled" });
                        this.container.visible = false;
                        this.container.renderable = false;
                    },
                }
            );
        }

        if (props.state === "visible") {
            this.shapes.form.setProps({ state: "enabled" });

            gsap.fromTo(
                this.container,
                {
                    alpha: 0,
                },
                {
                    alpha: 1,
                    duration: TransitionDuration,
                    onStart: () => {
                        this.container.visible = true;
                        this.container.renderable = true;
                    },
                }
            );

            // gsap.fromTo(
            //     this.shapes.image.container.scale,
            //     {
            //         x: 0.6,
            //         y: 0.6,
            //     },
            //     {
            //         x: 0.9,
            //         y: 0.9,
            //         duration: TransitionDuration,
            //         ease: "none",
            //     }
            // );

            AudioHelper.playAudio(this.sound);
        }
    }

    private onClick = () => {
        void this.hide();
    };
}
